.dropdown{
    width: 100%;  
    height: 50px;
    outline: none !important;
}

.control_error{
    outline: none !important;
    border-color: red;
    height: 50px;
    border-radius: 10px;
    box-shadow: 1px 1px 10px  red;
}

.menu {
       outline: none !important;
}
.control {
    outline: none !important;
    border-color: lightgrey;
    height: 50px;
    border-radius: 10px;
    box-shadow: 1px 1px 10px  lightgray;

}
.placeholder{
    outline: none !important;
}
.arrow{
    outline: none !important;
}